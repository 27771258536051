import './CellFirstCol.scss';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useModalContext } from '../../../context/ModalContext';
import { ModalStatuses } from '../../../types/enums';
import { textSlice } from '../../../utils';
import { AlertIcons, ArrowLeftIcon, EditIcon, PlusIcons } from '../../icons';

type CellFirstColType = {
  content?: any;
  success?: boolean;
  createScaleMapTaskMutation: (value: any) => void;
  selectFirstCol: number;
  setSelectFirstCol: (value: number) => void;
  updateScaleMapTaskMutation: (value: any) => void;
  deleteScaleMapTaskMutation: (value: any) => void;
  index: number;
  setFilterStatus: (value: number) => void;
  isLineSuccesses: boolean;
  setSelectTask: (value: any) => void;
  selectTask: number | null;
  hideSuccessInner: boolean;
  hoveredTaskId: number | null;
};

export const CellFirstCol = ({
  content,
  createScaleMapTaskMutation,
  selectFirstCol,
  setSelectFirstCol,
  updateScaleMapTaskMutation,
  deleteScaleMapTaskMutation,
  index,
  setFilterStatus,
  isLineSuccesses,
  setSelectTask,
  selectTask,
  hoveredTaskId,
}: CellFirstColType) => {
  const { fileId } = useParams();
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const showState2 = content.length > 0;
  const [initOrder, setInitOrder] = useState(true);

  useEffect(() => {
    // update after change order list
    setInitOrder(true);
  }, [selectTask]);

  const handleCreateTask = () => {
    handleSetModalStatus(ModalStatuses.ADD_FILE_TASK_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: {
          name: string;
          status: number;
          isExclamationPoint: boolean;
          isMain: boolean;
          tagsId: [string | undefined];
          calendarTaskTextColor?: string;
        }) => {
          createScaleMapTaskMutation({
            variables: {
              input: {
                name: value?.name,
                scaleMapFileId: parseInt(fileId as string),
                column: 1,
                row: index + 1,
                order: content?.length,
                isExclamationPoint: value?.isExclamationPoint,
                isMain: value?.isMain,
                tags: value?.tagsId?.map((e) => parseInt(e || '')),
                calendarTaskTextColor: value?.calendarTaskTextColor || '',
              },
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: undefined,
      },
    ]);
  };

  const handleUpdateModal = ({ task }: any) => {
    handleSetModalStatus(ModalStatuses.ADD_FILE_TASK_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: {
          name: string;
          status: number;
          isMain: boolean;
          isExclamationPoint: boolean;
          tagsId: [string | undefined];
          calendarTaskTextColor?: string;
        }) => {
          updateScaleMapTaskMutation({
            variables: {
              input: {
                id: task?.id,
                name: value?.name,
                scaleMapFileId: parseInt(fileId as string),
                statusId: value?.status,
                isMain: value?.isMain,
                isExclamationPoint: value?.isExclamationPoint,
                tags: value?.tagsId?.map((e) => parseInt(e || '')),
                calendarTaskTextColor: value?.calendarTaskTextColor || '',
              },
            },
          });
        },
      },
      {
        key: 'onCallbackDelete',
        value: () => {
          deleteScaleMapTaskMutation({
            variables: {
              id: task?.id,
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: {
          name: task?.name,
          status: task?.status,
          isMain: task?.isMain,
          isExclamationPoint: !!task?.isExclamationPoint,
          tagsId: task?.tags?.map((e: any) => e.id),
          calendarTaskTextColor: task?.calendarTaskTextColor,
        },
      },
    ]);
  };

  if (showState2) {
    return (
      <div
        className={`CellFirstCol__state2 ${
          isLineSuccesses && 'CellFirstCol__state2-black'
        }`}
        key={content?.id}
      >
        {content?.map((task: any, index: number) => {
          const general = content?.length > 1 && index === 0;

          if (task.order !== index && initOrder) {
            updateScaleMapTaskMutation({
              variables: {
                input: {
                  id: task?.id,
                  order: index,
                },
              },
            });
            setInitOrder(false);
          }

          return (
            <div
              className={`${
                selectFirstCol === task?.id || hoveredTaskId === task.id
                  ? 'CellFirstCol__state2-select'
                  : ''
              } CellFirstCol__state2-element`}
              onClick={() => {
                setSelectFirstCol(task?.id);
                setFilterStatus(0);
              }}
              key={task?.id}
            >
              <div
                className={
                  general ? 'CellFirstCol__state2__gray' : 'CellFirstCol__state2__empty'
                }
              >
                {/* <div id={task?.id} className='invisibleAnchor'></div> */}
                {task?.isExclamationPoint && <AlertIcons color='black' className='mr5' />}
                {task?.name}
              </div>

              <div className='CellFirstCol__state2-icons'>
                {content?.length !== index + 1 && (
                  <ArrowLeftIcon
                    color='black'
                    className='arrowBottom'
                    onClick={() => {
                      updateScaleMapTaskMutation({
                        variables: {
                          input: {
                            id: task?.id,
                            order: task.order + 1,
                          },
                        },
                      });
                      updateScaleMapTaskMutation({
                        variables: {
                          input: {
                            id: content[index + 1]?.id,
                            order: index,
                          },
                        },
                      });
                    }}
                  />
                )}
                {index !== 0 && (
                  <ArrowLeftIcon
                    color='black'
                    className='arrowTop'
                    onClick={() => {
                      updateScaleMapTaskMutation({
                        variables: {
                          input: {
                            id: task?.id,
                            order: task.order - 1,
                          },
                        },
                      });
                      updateScaleMapTaskMutation({
                        variables: {
                          input: {
                            id: content[index - 1]?.id,
                            order: index,
                          },
                        },
                      });
                    }}
                  />
                )}

                <div
                  className='CellFirstCol__state2-change'
                  onClick={() => {
                    if (selectTask === task?.id) {
                      setSelectTask(null);
                    } else {
                      setSelectTask(task?.id);
                    }
                  }}
                ></div>
                <EditIcon
                  color='black'
                  width={14}
                  height={14}
                  onClick={() => {
                    handleUpdateModal({ task });
                  }}
                />
              </div>
            </div>
          );
        })}
        <div
          onClick={() => {
            handleCreateTask();
          }}
          className='CellFirstCol__state2-button'
          style={{
            justifyContent: 'center',
          }}
        >
          <div className='CellFirstCol__child'>
            <PlusIcons color='#149939' width='24' height='24' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className='CellFirstCol__state1'
      onClick={() => {
        handleCreateTask();
      }}
    >
      <div className='CellFirstCol__child'>
        <PlusIcons color='#149939' width='24' height='24' />
      </div>
    </div>
  );
};
